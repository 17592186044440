body {
  margin: 0;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.link {
  color: inherit;
  text-decoration: inherit;
}

div[data-cy="window-chatthing"]  {
  bottom: 20px !important;
  right: 20px !important;
  max-width: 500px !important;
  max-height: 750px !important;
}
